import _objectSpread from "/Users/2024/gold/gold_admin_template/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import { mapGetters, mapState } from 'vuex';
import _variables from '@/styles/variables.scss';
import { checkWithdraw, getWithdraw } from '@/api/withdraw';
export default {
  name: 'Layout',
  data: function data() {
    return {
      withdrawId: 0,
      rechargeId: 0,
      playgameId: 0,
      registerId: 0,
      runTime: 0,
      orderCount: 0,
      withdrawCount: 0,
      rechargeCount: 0
    };
  },
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(['roles'])), mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    variables: function variables() {
      return _variables;
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    // 页面加载完成后的操作
    console.log("页面加载完成");

    if (this.roles.includes('kf') || this.roles.includes('admin') || this.roles.includes('webadmin')) {
      setInterval(function () {
        /*
                this.$notify({
                  message: '恭喜你，这是一条成功消息',
                  type: 'success',
                  position: 'bottom-right'
                })
                var ttsAudio = document.getElementById('s1');
                ttsAudio.play();
                */
        console.log("runtime:", _this.runTime++);
        checkWithdraw().then(function (res) {
          var have_new = false;

          if (res.code == 200) {
            console.log("runtime:", 222);
            _this.withdrawCount = res.data.withdrawCount;
            _this.rechargeCount = res.data.rechargeCount;
            _this.orderCount = res.data.orderCount; //console.log(res)

            var times = 0;

            if (res.data.recharge > 0 && res.data.recharge != _this.rechargeId) {
              console.log("xxxxx", _this.rechargeId, res.data.recharge);
              _this.rechargeId = res.data.recharge;
              have_new = true;

              _this.$notify({
                duration: 6000,
                message: '有新的【充值】，请及时处理',
                type: 'success',
                position: 'bottom-right'
              });

              times++;
            }

            if (res.data.withdraw > 0 && res.data.withdraw != _this.withdrawId) {
              console.log("xxxxx222", _this.withdrawId, res.data.withdraw);
              setTimeout(function () {
                _this.$notify({
                  duration: 6000,
                  message: '有新的【提现】，请及时处理',
                  type: 'success',
                  position: 'bottom-right'
                });
              }, times * 100);
              have_new = true;
              times++;
              _this.withdrawId = res.data.withdraw;
            }

            if (res.data.playgame > 0 && res.data.playgame != _this.playgameId) {
              console.log("xxxxx333", _this.playgameId, res.data.playgame);
              setTimeout(function () {
                _this.$notify({
                  duration: 6000,
                  message: '有用户下注，请及时处理',
                  type: 'success',
                  position: 'bottom-right'
                });
              }, times * 100);
              times++;
              have_new = true;
              _this.playgameId = res.data.playgame;
            }

            if (res.data.register > 0 && res.data.register != _this.registerId) {
              setTimeout(function () {
                _this.$notify({
                  duration: 6000,
                  message: '有新的用户注册',
                  type: 'success',
                  position: 'bottom-right'
                });
              }, times * 100);
              times++;
              have_new = true;
              _this.registerId = res.data.register;
            }

            if (have_new == true) {
              //console.log("1")
              var ttsAudio = document.getElementById('s1'); //console.log(ttsAudio)

              ttsAudio.play();
            }
          }
        });
      }, 5000);
    } // 你可以在这里执行其他操作，比如发起网络请求、操作DOM等

  },
  created: function created() {}
};