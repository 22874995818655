var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "div",
                  { staticClass: "right-menu-item t1" },
                  [
                    _vm._v("提现: "),
                    _c("router-link", { attrs: { to: "/game_pop/withdraw" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.withdrawCount))])
                    ]),
                    _vm._v(" 充值: "),
                    _c("router-link", { attrs: { to: "/game_pop/recharge" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.rechargeCount))])
                    ]),
                    _vm._v(" 订单: "),
                    _c(
                      "router-link",
                      { attrs: { to: "/game_pop/currency_order" } },
                      [_c("span", [_vm._v(_vm._s(_vm.orderCount))])]
                    )
                  ],
                  1
                ),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" }
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" }
                })
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "hover" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("../../assets/user.png") }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/index" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出登录")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }